<template id="template-date">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="fieldData.label[selectedLanguage]"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden)"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      id="collapse-5"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label v-if="$store.state.rightAlign">
              <span dir="rtl" class="mr-1">
                {{ label ? label : fieldData.label[selectedLanguage] }}
              </span>

              <span
                v-if="
                  parseInt(
                    form1.formSection[sectionIndex].isManualComponentEnums
                  ) === 1
                "
              >
                {{
                  parseInt(form1.enableSectionFieldEnumeration) === 1
                    ? fieldData.manualEnumValue
                    : ""
                }}
              </span>
              <span v-else>
                {{
                  parseInt(form1.enableSectionFieldEnumeration) === 1
                    ? sectionIndex + 1 + "." + (fieldIndex + 1)
                    : ""
                }}
              </span>
              <span
                v-if="parseInt(fieldData?.validation?.required) === 1"
                class="required-span"
                >*</span
              >
            </label>

            <label v-else>
              <span
                v-if="parseInt(fieldData?.validation?.required) === 1"
                class="required-span"
                >*</span
              >
              <span
                v-if="
                  parseInt(
                    form1.formSection[sectionIndex].isManualComponentEnums
                  ) === 1
                "
              >
                {{
                  parseInt(form1.enableSectionFieldEnumeration) === 1
                    ? fieldData.manualEnumValue
                    : ""
                }}
              </span>
              <span v-else>
                {{
                  parseInt(form1.enableSectionFieldEnumeration) === 1
                    ? sectionIndex + 1 + "." + (fieldIndex + 1)
                    : ""
                }}
              </span>
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>

            <b-input-group
              v-if="fieldData.allowCurrentDate"
              :class="
                $store.state.rightAlign ? 'text-right mb-3' : 'text-left mb-3'
              "
            >
              <DatePicker
                :id="fieldData.id"
                class="custom-date-picker"
                :datePickerOptions="{
                  value: name,
                  state: dateState,
                  enableTimePicker: false,
                  disabled: isDisabled === 1,
                  disabledDates: disabledDatesForCurrent,
                  class: $store.state.rightAlign
                    ? 'date-picker-right'
                    : 'date-picker-left',
                  'onUpdate:modelValue': (value) => {
                    name = value;
                    setValue(value);
                  },
                  onBlur: checkValidate,
                  onFocus: () => {
                    isFocus = true;
                  }
                }"
              />
            </b-input-group>

            <b-input-group
              v-else
              :class="
                $store.state.rightAlign ? 'text-right mb-3' : 'text-left mb-3'
              "
            >
              <DatePicker
                :id="fieldData.id"
                class="custom-date-picker"
                :datePickerOptions="{
                  value: name,
                  state: dateState,
                  enableTimePicker: false,
                  disabled: isDisabled === 1,
                  class: $store.state.rightAlign
                    ? 'date-picker-right'
                    : 'date-picker-left',
                  'onUpdate:modelValue': (value) => {
                    name = value;
                    setValue(value);
                  },
                  onBlur: checkValidate,
                  onFocus: () => {
                    isFocus = true;
                  }
                }"
              />
            </b-input-group>
            <span
              v-if="isFocus && fieldData.validation && v$.name.$invalid"
              class="text-danger"
              >{{ fieldData.validationMessage[selectedLanguage] }}</span
            >
          </b-form-group>
        </b-col>
      </b-row>

      <!-- show advance field options -->

      <b-modal
        cancel-title="Close"
        @hidden="onUpdateSettings"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        title="Date Advanced Settings"
      >
        <AdvanceDateSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>
<script>
import { required } from "@vuelidate/validators";
import DatePicker from "@/molecules/DatePicker/DatePicker";
import moment from "moment";
import AdvanceDateSettings from "../advanceSettingsCompenent";
import MenuButtons from "../menuButtons";
import { mapGetters, mapState, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "DateGroup",
  components: {
    AdvanceDateSettings,
    MenuButtons,
    DatePicker
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      isFocus: false,
      isError: false,
      isSuccess: false,
      showSettings: false,
      collapse: true,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      name: this.fieldData.value || null,
      disabledDatesForCurrent: {
        to: new Date(),
        from: new Date()
      },
      disabledDates: {
        to:
          this.fieldData.advance && this.fieldData.advance.disabledMinDateInput
            ? new Date(Date.now() - 864e5)
            : this.fieldData.advance && this.fieldData.advance.minDate
            ? new Date(this.fieldData.advance.minDate)
            : "", // Disable all dates up to specific date
        from:
          this.fieldData.advance && this.fieldData.advance.disabledMaxDateInput
            ? new Date()
            : this.fieldData.advance && this.fieldData.advance.maxDate
            ? new Date(this.fieldData.advance.maxDate)
            : "" // Disable all dates after specific date
      }
    };
  },
  validations: {
    name: {
      required
    }
  },
  mounted() {
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === routeNames[routeKeys.USER_FORM] ||
      this.$route.name === "MyCompanyView"
    ) {
      this.setFieldValue();
    }
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (this.fieldData.allowCurrentDate) {
        this.setValue(new Date());
      }
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (this.v$ && !this.v$.$invalid) {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          } else {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          }
        }
      }
    });
    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  computed: {
    ...mapGetters(["form1", "selectedLanguage", "userFormSubmitArray"]),
    ...mapState(["toggleAllCoppalse"]),
    dateState() {
      if (
        !this.name &&
        this.fieldData.validation?.required === 1 &&
        this.isFocus
      ) {
        return false;
      }
      return this.name && true;
    }
  },

  watch: {
    toggleAllCoppalse(vlue) {
      this.collapse = vlue;
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },

    checkValidate() {
      if (this.name?.length >= 3) {
        (this.isError = false), (this.isSuccess = true);
      } else {
        (this.isError = true), (this.isSuccess = false);
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.forEach((el) => {
              this.name = el.postValue || null;
            });
          }
        });
    },

    setValue(e) {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === routeNames[routeKeys.USER_FORM] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.name = e;
        if (e) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;

        this.userFormSubmitArray.find((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[0] = {
              postValue: e,
              postName:
                (this.fieldData.attributes &&
                  this.fieldData.attributes.postName) ||
                "postNameInput"
            };
          }
        });
      }
    },

    customFormatter(date) {
      let customFormat = this.fieldData.format;
      if (customFormat == "default") {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return moment(date).locale("en").format(customFormat);
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style>
.custom-date-picker {
  width: 100%;
  border: none;
}
.date-picker-right {
  text-align: right !important;
}
.date-picker-left {
  text-align: left !important;
}
.input-group-text-left {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  width: 500px !important;
  background: #dee2e6 !important;
}
</style>
