<template>
  <div class="ubo-profile-template">
    <div class="ubo-profile-template__header-container">
      <ProfileHeader
        class="ubo-profile-template__profile-header"
        :heading="getUBOName"
        :subheading="getCompanyName"
        :icon-options="iconOptions"
        :c-t-a-options="CTAOptions"
      />
      <BaseText
        v-if="getUBODateOfChange"
        :size="typographySize.BODY_TEXT_BOLD"
        hasDefaultSpacingRemoved
        tag="h2"
      >
        Last update: {{ getUBODateOfChange }}
      </BaseText>
    </div>
    <Menu :itemOptions="itemOptions" @click="handleMenuClick" />
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :UBO-entity-profile-data="UBOEntityData"
        :UBO-entity-profile-error-message="UBOEntityErrorMessage"
        :UBO-entity-data-is-loading="UBOEntityDataIsLoading"
        :due-diligence-menu-options="dueDiligenceMenuOptions"
        :due-diligence-report-page-heading="getDueDiligenceReportPageHeading"
        :due-diligence-report-data="UBODueDiligenceReportData"
        :due-diligence-report-data-is-loading="isDueDiligenceTabDataLoading"
        :due-diligence-report-data-error-message="UBODueDiligenceErrorMessage"
      />
    </router-view>
  </div>
</template>

<script>
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import Menu from "@/molecules/Menu/Menu";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  typographySize,
  ultimateBeneficialOwnersKeysAPIDTO,
  routeNames,
  routeKeys,
  urls,
  defaultIcons,
  icons
} from "@/constants";
import { isRouteNameMatched } from "@/utils";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "UltimateBeneficialOwnersProfile",
  components: {
    BaseText,
    ProfileHeader,
    Menu
  },
  data() {
    return {
      typographySize
    };
  },
  async created() {
    await this.fetchUBOEntityData(this.makeUBOEntityQueryParameters());

    this.getTabDataConditionally();
  },
  watch: {
    $route() {
      this.getTabDataConditionally();
    }
  },
  computed: {
    ...mapState({
      UBOEntityData: (state) => state.uboEntity.UBOEntityData,
      UBOEntityDataIsLoading: (state) => state.uboEntity.UBOEntityDataIsLoading,
      UBOEntityErrorMessage: (state) => state.uboEntity.UBOEntityErrorMessage,
      UBODueDiligenceReportData: (state) =>
        state.uboEntity.UBODueDiligenceReportData,
      UBODueDiligenceReportDataIsLoading: (state) =>
        state.uboEntity.UBODueDiligenceReportDataIsLoading,
      UBODueDiligenceErrorMessage: (state) =>
        state.uboEntity.UBODueDiligenceErrorMessage
    }),
    isDueDiligenceTabDataLoading() {
      return (
        this.UBOEntityDataIsLoading || this.UBODueDiligenceReportDataIsLoading
      );
    },
    uboThirdPartyId() {
      return this.UBOEntityData?.uboThirdPartyId;
    },
    isDueDiligenceTabActive() {
      return isRouteNameMatched({
        matchedRoutes: this.$route?.matched,
        name: routeNames[
          routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE
        ]
      });
    },
    itemOptions() {
      return [
        {
          textOptions: {
            text: "Details"
          },
          path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS(
            this.$route.params.thirdPartyId,
            this.$route.params.memberId
          ),
          isActive: isRouteNameMatched({
            matchedRoutes: this.$route?.matched,
            name: routeNames[
              routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS
            ]
          })
        },
        {
          textOptions: {
            text: "Due diligence"
          },
          path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE(
            this.$route.params.thirdPartyId,
            this.$route.params.memberId
          ),
          isActive: this.isDueDiligenceTabActive
        }
      ];
    },
    // DUE DILIGENCE
    dueDiligenceMenuOptions() {
      return {
        theme: "secondary",
        type: "aside",
        itemOptions: [
          {
            textOptions: {
              text: "Due diligence reports",
              theme: "primary"
            },
            path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS(
              this.$route.params.thirdPartyId,
              this.$route.params.memberId
            ),
            isActive: isRouteNameMatched({
              matchedRoutes: this.$route?.matched,
              name: routeNames[
                routeKeys
                  .ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS
              ]
            })
          }
        ]
      };
    },
    getDueDiligenceReportPageHeading() {
      return "Due diligence reports";
    },
    getUBOName() {
      return this.UBOEntityData?.name || "";
    },
    getUBODateOfChange() {
      return this.UBOEntityData?.dateOfChange || "";
    },
    getCompanyName() {
      return `UBO of: ${this.UBOEntityData?.companyName || ""}`;
    },
    CTAOptions() {
      return {
        ...defaultIcons.EDIT,
        value: null,
        icon: null,
        type: "button"
      };
    },
    iconOptions() {
      return {
        ...defaultIcons.DEFAULT,
        icon: icons.SITEMAP,
        size: 20
      };
    }
  },
  methods: {
    ...mapMutations({
      resetUboEntity: "uboEntity/resetUboEntity"
    }),
    ...mapActions({
      fetchUBOEntityData: "uboEntity/fetchUBOEntityData",
      fetchUBODueDiligenceReportData:
        "uboEntity/fetchUBODueDiligenceReportData"
    }),
    getTabDataConditionally() {
      if (
        this.isDueDiligenceTabActive &&
        !this.UBODueDiligenceReportData?.length &&
        this.uboThirdPartyId
      ) {
        this.fetchUBODueDiligenceReportData({
          thirdPartyId: this.uboThirdPartyId
        });
      }
    },
    makeUBOEntityQueryParameters() {
      return {
        [ultimateBeneficialOwnersKeysAPIDTO.THIRD_PARTY_ID]:
          this.$route.params.thirdPartyId,
        [ultimateBeneficialOwnersKeysAPIDTO.MEMBER_ID_UNDERSCORED]:
          this.$route.params.memberId
      };
    },
    handleMenuClick(id) {
      const path = this.itemOptions?.[id]?.path;

      this.$router?.push(path)?.catch((error) => error);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetUboEntity();
    next();
  }
};
</script>

<style scoped lang="scss">
.ubo-profile-template {
  &__header-container {
    display: flex;
    justify-content: space-between;
    margin: $spacing24 $spacing32 $spacing24 0;
    align-items: flex-start;
  }

  &__profile-header {
    margin: 0;
  }
}
</style>
