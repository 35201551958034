import {
  routeNames,
  urls
} from "@/constants";
import store from "@/store";
import thirdPartyRoutes from "@/routes/thirdParty.routes";

const routesNameAllowedToTp = thirdPartyRoutes.reduce(
  (acc, { name, children }) => {
    if (name) {
      acc[name] = name;
      children?.forEach(({ name }) => {
        acc[name] = name;
      });
    }
    return acc;
  },
  {}
);

const checkAccessibilityRouteGard = ({ to = {}, router = {} } = {}) => {
  if (to?.meta?.requiresAuth && !store.getters["auth/isUserAuthenticated"]) {
    router.replace(urls.LOGIN);
  }
};

const hasAccessToRoute = (routeName = "") => {
  if (store.getters["user/isUserSuperAdmin"]) return true;

  const appRoutePermissions = store.getters["permissions/appRoutePermissions"];

  return appRoutePermissions?.[routeName];
};

const eb360RouteGard = ({
  to = {},
  router = {},
  _hasAccessToRoute = hasAccessToRoute
} = {}) => {
  if (
    !store.getters["user/isUserThirdParty"] &&
    store.getters["auth/isUserAuthenticated"] &&
    !_hasAccessToRoute(to.name)
  ) {
    router.replace(urls.ACCESS_RESTRICTED);
  }
};

const thirdPartyRouterGard = ({
  to = {},
  router = {},
  routes = routesNameAllowedToTp
} = {}) => {
  if (store.getters["user/isUserThirdParty"]) {
    if (store.getters["user/isUserNotUpdated"]) {
      if (to.path !== urls.CREATE_PROFILE) {
        router.replace(urls.CREATE_PROFILE);
      }
    } else if (!routes[to.name]) {
      router.replace(urls.DASHBOARD);
    }
  }
};

const getUsersHomepage = async () => {
  const { companyId } = store.state.company;
  const { userId } = store.state.user;

  if (companyId && userId) {
    if (!store.getters["userSettings/hasHomePageAndPageList"]) {
      await store.dispatch("userSettings/getUserSettings", {
        companyId,
        userId
      });
    }

    const { accountHomePage, userHomePage } = store.state.userSettings;

    if (userHomePage?.value) {
      return { path: userHomePage.value };
    }

    if (accountHomePage?.value) {
      return { path: accountHomePage.value };
    }

    return { name: routeNames.MANAGE_THIRD_PARTY };
  } else {
    return { name: routeNames.LOGIN };
  }
};

const isRouteNameMatched = ({ matchedRoutes = [], name = "" }) =>
  !!matchedRoutes?.some((route) => route?.name === name);

const navigateToURL = (url, target = "_blank") => window.open(url, target);

export {
  navigateToURL,
  getUsersHomepage,
  thirdPartyRouterGard,
  checkAccessibilityRouteGard,
  hasAccessToRoute,
  eb360RouteGard,
  isRouteNameMatched
};
