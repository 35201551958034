<template>
  <div
    v-if="isVisible"
    :class="['base-info-box', `base-info-box__theme--${getTheme}`]"
    data-test-id="base-info-box"
  >
    <BaseIcon v-if="hasIcon" class="base-info-box__icon" v-bind="iconOptions" />
    <span
      class="base-info-box__text"
      v-html="text"
      data-test-id="base-info-box__text"
    />
    <BaseIcon
      v-if="hasClose"
      class="base-info-box__close"
      data-test-id="base-info-box__close"
      icon="eb-icon-times"
      :theme="themes.SECONDARY"
      :size="12"
      @click="closeInfoBox"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { themes, icons } from "@/constants";
import { isValidTheme } from "@/utils/typography/typography.utils";

export default defineComponent({
  name: "BaseInfoBox",
  compatConfig: {
    MODE: 3
  },
  components: {
    BaseIcon
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    hasClose: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: themes.INFO,
      validator: (value) => Object.values(themes).includes(value)
    },
    text: {
      type: String,
      required: true
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const getTheme = computed(() =>
      isValidTheme(props.theme) ? props.theme : themes.INFO
    );
    const hasIcon = computed(() => !!props.iconOptions?.icon);

    const isVisible = computed(() =>
      props.hasClose ? props.modelValue : true
    );

    const closeInfoBox = () => {
      emit("update:modelValue", false);
    };

    return {
      icons,
      getTheme,
      hasIcon,
      isVisible,
      closeInfoBox,
      themes
    };
  }
});
</script>

<style lang="scss" scoped>
.base-info-box {
  display: flex;
  align-items: flex-start;
  padding: $spacing20;
  gap: $spacing12;
  border-radius: $border-radius8;

  &__icon {
    align-self: center;
  }

  &__text {
    flex: 1;
    color: $deep-sea;
    text-align: left;
  }

  &__close {
    cursor: pointer;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      @include get-background-color($secondary-color);
      @include get-border-color($primary-color);
    }
  }
}
</style>
