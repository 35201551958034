<template>
  <div class="third-party-profile">
    <BaseLoader v-if="isLoadingThirdPartyDetails" />
    <template v-else>
      <BaseInfoBox
        v-if="feedbackMessage"
        :text="feedbackMessage"
        :theme="themes.INFO"
        :icon-options="defaultIcons.INFO"
      />
      <BaseInfoBox
        v-if="errorMessage"
        :text="errorMessage"
        :theme="themes.ERROR"
        :icon-options="defaultIcons.HIGH"
      />
      <ProfileHeader
        :heading="thirdPartyDetails.comp_name_eng || ''"
        :subheading="thirdPartyDetails.country || ''"
      />
      <TabbedMenu
        :tabs="getRiskAlertTabs"
        :theme="themes.GRADIENT_TWILIGHT_GREY"
        class="third-party-profile__tabbed-menu"
      />
    </template>
  </div>
</template>

<script>
import ThirdPartyRiskAlerts from "@/organisms/ThirdPartyRiskAlerts/ThirdPartyRiskAlerts";
import ThirdPartyRiskAlertsDetails from "@/organisms/ThirdPartyRiskAlertsDetails/ThirdPartyRiskAlertsDetails";
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { TabbedMenu } from "@eb360-engineering/fe-component-library";
import { defaultIcons, themes, typographySize } from "@/constants";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "ThirdPartyProfileTemplate",
  components: {
    BaseLoader,
    BaseInfoBox,
    ProfileHeader,
    TabbedMenu
  },
  data() {
    return {
      themes,
      defaultIcons,
      feedbackMessage: "",
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails
    }),
    getRiskAlertTabs() {
      return [
        {
          id: "tab1",
          menuOptions: {
            textOptions: {
              text: "Summary",
              size: typographySize.BODY_TEXT_SMALL_BOLD
            }
          },
          component: ThirdPartyRiskAlerts,
          componentOptions: {}
        },
        {
          id: "tab2",
          menuOptions: {
            textOptions: {
              text: "Details",
              size: typographySize.BODY_TEXT_SMALL_BOLD
            }
          },
          component: ThirdPartyRiskAlertsDetails,
          componentOptions: {}
        }
      ];
    }
  },
  async created() {
    if (isEmpty(this.thirdPartyDetails)) {
      await this.fetchThirdPartyDetails(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      fetchThirdPartyDetails: "thirdParty/fetchThirdPartyDetails"
    })
  }
};
</script>

<style scoped lang="scss">
.third-party-profile {
  padding: $spacing24 0;
  &__tabbed-menu {
    margin-left: $spacing16;
  }
}
</style>
