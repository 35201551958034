<template>
  <div
    class="third-party-risk-alerts-details__wrapper"
    data-test-id="third-party-risk-details__wrapper"
  >
    <div
      class="third-party-risk-alerts-details"
      data-test-id="third-party-risk-alerts-details"
    >
      <BaseLoader v-if="isThirdPartyRiskAlertsDetailsLoading" />
      <div
        v-else
        class="third-party-risk-alerts-details__tab"
        data-test-id="third-party-risk-alerts-details__tab"
      >
        <ThirdPartyRiskAlertsHeader
          v-if="hasTableRows"
          :headerOptions="headerOptions"
        />
        <StickyTable
          :table-headers="riskAlertsDetailHeaders"
          :table-rows="riskAlertsDetailTableRows"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ThirdPartyRiskAlertsHeader from "@/organisms/ThirdPartyRiskAlertsHeader/ThirdPartyRiskAlertsHeader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import TextDisplayList from "@/molecules/TextDisplayList/TextDisplayList";
import {
  shapes,
  typographySize,
  tableOptions,
  riskAlertDetailsKeys,
  riskAlertDetailsTableHeaders
} from "@/constants";

export default {
  name: "ThirdPartyRiskAlertsDetails",
  components: {
    ThirdPartyRiskAlertsHeader,
    StickyTable,
    BaseLoader
  },
  data() {
    return {
      riskAlertsDetailHeaders: [],
      riskAlertsDetailTableRows: []
    };
  },
  computed: {
    ...mapState({
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      thirdPartyRiskAlertsDetails: (state) =>
        state.thirdParty.thirdPartyRiskAlertsDetails,
      isThirdPartyRiskAlertsDetailsLoading: (state) =>
        state.thirdParty.isThirdPartyRiskAlertsDetailsLoading,
      headerOptions() {
        return {
          heading: "Third party risk alert details",
          description:
            "The following risk alerts were coming from the following service: IDD+",
          alertsCount: this.thirdPartyRiskAlertsDetails?.total || 0,
          hasLegendVisible: false
        };
      },
      makeListForTable() {
        return (
          this.thirdPartyRiskAlertsDetails?.data?.map(
            ({
              alertType,
              category,
              stage,
              liableParty,
              insertedDate,
              remediationDate,
              eventDate,
              eventDescription,
              sourceName,
              sourceUrl
            }) => ({
              [riskAlertDetailsKeys.ALERT_TYPE]: alertType || "-",
              [riskAlertDetailsKeys.CATEGORY]: `${category} / ${stage}` || "-",
              [riskAlertDetailsKeys.LIABLE_PARTY]: liableParty || "-",
              [riskAlertDetailsKeys.INSERTED_DATE]: insertedDate || "-",
              [riskAlertDetailsKeys.REMEDIATION_DATE]: remediationDate || "-",
              [riskAlertDetailsKeys.MORE_INFO]: "moreInfo",
              [tableOptions.ACCORDION_OPTIONS]: {
                [riskAlertDetailsKeys.EVENT_DATE]: eventDate || "-",
                [riskAlertDetailsKeys.EVENT_DESCRIPTION]:
                  eventDescription || "-",
                [riskAlertDetailsKeys.SOURCE_NAME]: sourceName || "-",
                [riskAlertDetailsKeys.SOURCE_URL]: sourceUrl || "-"
              }
            })
          ) || []
        );
      }
    }),
    hasTableRows() {
      return !!this.thirdPartyRiskAlertsDetails?.data?.length;
    }
  },
  async created() {
    await this.fetchThirdPartyRiskAlertsDetailsWrapper(this.$route.params.id);
    this.setRiskAlertsDetailsTableHeaders();
    this.setRiskAlertsDetailsTableRows();
  },
  methods: {
    ...mapActions({
      fetchThirdPartyRiskAlertsDetails:
        "thirdParty/fetchThirdPartyRiskAlertsDetails"
    }),
    async fetchThirdPartyRiskAlertsDetailsWrapper(thirdPartyId) {
      try {
        (await this.fetchThirdPartyRiskAlertsDetails(thirdPartyId)) || "";
      } catch ({ message = "" }) {
        console.log("message", message);
      }
    },
    setRiskAlertsDetailsTableHeaders() {
      this.riskAlertsDetailHeaders = this.makeListForTable?.length
        ? Object.keys(this.makeListForTable[0]).reduce((acc, key) => {
            if (riskAlertDetailsTableHeaders[key]) {
              acc.push({
                value: key,
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: riskAlertDetailsTableHeaders[key],
                  size: typographySize.BODY_TEXT_BOLD
                },
                styles: {
                  textAlign:
                    key === riskAlertDetailsKeys.MORE_INFO ? "center" : "left"
                }
              });
            }
            return acc;
          }, [])
        : [];
    },
    setRiskAlertsDetailsTableRows() {
      this.riskAlertsDetailTableRows =
        this.makeListForTable?.map((row, rowIndex) =>
          Object.keys(row).reduce((acc, key) => {
            if (key === tableOptions.ACCORDION_OPTIONS) {
              acc[key] = {
                [tableOptions.IS_ACCORDION_VISIBLE]: false,
                component: TextDisplayList,
                componentOptions: {
                  list: this.makeAccordionData(row[key])
                },
                styles: {
                  textAlign: "left"
                }
              };
            } else if (key === riskAlertDetailsKeys.MORE_INFO) {
              acc[key] = {
                component: CallToAction,
                componentOptions: {
                  icon: "chevron-down",
                  iconShape: shapes.CIRCLE,
                  iconSize: 12,
                  onClick: () => {
                    this.handleMoreInfoClick(rowIndex);
                  }
                },
                styles: {
                  textAlign: "center"
                }
              };
            } else {
              acc[key] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: row[key]
                },
                styles: {
                  padding: "20px"
                }
              };
            }
            return acc;
          }, {})
        ) || [];
    },
    makeAccordionData(values) {
      return [
        {
          labelOptions: {
            text: "Event date"
          },
          valueOptions: {
            text: values?.[riskAlertDetailsKeys.EVENT_DATE] || "-"
          }
        },
        {
          labelOptions: {
            text: "Description"
          },
          valueOptions: {
            text: values?.[riskAlertDetailsKeys.EVENT_DESCRIPTION] || "-"
          }
        },
        {
          labelOptions: {
            text: "Source Name"
          },
          valueOptions: {
            text: values?.[riskAlertDetailsKeys.SOURCE_NAME] || "-"
          }
        },
        {
          labelOptions: {
            text: "Source Link"
          },
          valueOptions: {
            text: values?.[riskAlertDetailsKeys.SOURCE_URL] || "-",
            isHtml: true
          }
        }
      ];
    },
    handleMoreInfoClick(selectedIndex) {
      const accordionVisibilityValue =
        !this.riskAlertsDetailTableRows[selectedIndex][
          tableOptions.ACCORDION_OPTIONS
        ][tableOptions.IS_ACCORDION_VISIBLE];

      this.riskAlertsDetailTableRows[selectedIndex][
        riskAlertDetailsKeys.MORE_INFO
      ].componentOptions.icon = accordionVisibilityValue
        ? "chevron-up"
        : "chevron-down";

      this.riskAlertsDetailTableRows[selectedIndex][
        tableOptions.ACCORDION_OPTIONS
      ][tableOptions.IS_ACCORDION_VISIBLE] = accordionVisibilityValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.third-party-risk-alerts-details {
  &__wrapper {
    overflow-y: scroll;
    height: calc(100vh - 350px);
  }
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid $grey;
  border-radius: $spacing16;
  box-shadow: $box-shadow-dropdown;
}
</style>

