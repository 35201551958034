<template>
  <div class="ubo-change-log">
    <BaseLoader
      v-if="isUBOChangeLogDataLoading"
      class="ubo-change-log__loader"
    />
    <DataTable
      v-else-if="hasTableRows"
      :ag-grid-options="agGridOptions"
      grid-height="calc(100vh - 440px)"
      ref="ubo-change-log-table"
      @gridReady="onGridReady"
    />
    <BaseInfoBox
      v-else
      text="No recorded changes"
      :icon-options="infoIconOptions"
      :theme="themes.INFO"
    />
    <Modal
      v-if="showChangesModal"
      title="Existing ownership updates"
      @close-modal="handleModalClose"
      class="ubo-change-log__modal"
    >
      <template v-slot>
        <div class="ubo-change-log__modal-content">
          <div class="ubo-change-log__modal-content-container">
            <BaseText
              text="Previous"
              :size="typographySize.BODY_TEXT_BOLD"
              class="ubo-change-log__modal-content-heading"
            />
            <BaseText :text="rowChangesData.previous" />
          </div>
          <div class="ubo-change-log__modal-content-container">
            <BaseText
              text="Current"
              :size="typographySize.BODY_TEXT_BOLD"
              class="ubo-change-log__modal-content-heading"
            />
            <BaseText :text="rowChangesData.current" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, defineComponent, useTemplateRef, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { startCase } from "lodash";
import { defaultIcons, iconSizes, themes, typographySize } from "@/constants";
import { isString, isObject, isNumber } from "@/utils";
import { useUBOChangeLogTable } from "@/composables/UBOChangeLogTable/UBOChangeLogTable";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import Modal from "@/molecules/Modal/Modal";
import DataTable from "@/organisms/DataTable/DataTable";

export default defineComponent({
  name: "UltimateBeneficialOwnersChangeLog",
  components: {
    DataTable,
    BaseLoader,
    BaseInfoBox,
    Modal,
    BaseText
  },
  compatConfig: {
    MODE: 3
  },
  props: {
    organisationId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isUBOChangeLogDataLoading =
      store.state.thirdParty.isUBOChangeLogDataLoading;
    const table = useTemplateRef("ubo-change-log-table");

    store.dispatch(
      "thirdParty/fetchThirdPartyUBOChangeLog",
      props.organisationId
    );

    const infoIconOptions = computed(() => ({
      ...defaultIcons.INFO,
      size: iconSizes.SMALL,
      shapeSpacing: 2.33
    }));

    const {
      hasTableRows,
      agGridOptions,
      gridAPI,
      onGridReady,
      makeUBOTableRows,
      makeUBOTableHeaders,
      rowChangesData
    } = useUBOChangeLogTable({
      table,
      router,
      thirdPartyId: route?.params?.id
    });

    const showChangesModal = computed(() => !!rowChangesData.value);

    const listObjectValues = ({
      value = {},
      // eslint-disable-next-line no-use-before-define
      _setChangeValueAsText = setChangeValueAsText,
      _startCase = startCase
    } = {}) =>
      Object?.entries(value)?.map(([key, value]) => {
        // As we're not allowed to display references of duns or DnB to users (https://ethixbase.atlassian.net/browse/PD-5481)
        // These need to be removed from the title of any property.
        const regex = new RegExp("dnb|duns", "gi");
        const filteredKey = key.replaceAll(regex, "");

        return filteredKey
          ? `${_startCase(filteredKey)}: ${_setChangeValueAsText({
              value,
              joinValues: true
            })}`
          : "";
      }) || [];

    const handleArrayDataDisplay = ({
      list = [],
      // eslint-disable-next-line no-use-before-define
      _setChangeValueAsText = setChangeValueAsText
    }) =>
      list?.map((value) =>
        _setChangeValueAsText({ value, joinValues: true })
      ) || [];

    const setChangeValueAsText = ({
      value,
      joinValues = false,
      _isString = isString,
      _isNumber = isNumber,
      _isObject = isObject,
      _listObjectValues = listObjectValues,
      _handleArrayDataDisplay = handleArrayDataDisplay
    } = {}) => {
      if (_isString(value) && value?.length) {
        return value;
      }

      if (_isNumber(value)) {
        return value?.toString();
      }

      if (_isObject(value)) {
        return joinValues
          ? _listObjectValues({ value }).join(", ")
          : _listObjectValues({ value });
      }

      if (Array.isArray(value) && value?.length) {
        return _handleArrayDataDisplay(value);
      }

      return "-";
    };

    const mappedUBOChangeLogData = computed(() =>
      store.state.thirdParty.UBOChangeLogData?.map((item) => ({
        ...item,
        changes: {
          current: setChangeValueAsText({ value: item?.current }),
          previous: setChangeValueAsText({ value: item?.previous })
        }
      }))
    );

    const handleModalClose = () => {
      rowChangesData.value = null;
    };

    watch(
      () => rowChangesData.value,
      (value) => {
        showChangesModal.value = !!value;
      }
    );

    watch(
      () => mappedUBOChangeLogData.value,
      (data) => {
        if (data?.length) {
          makeUBOTableHeaders();
          makeUBOTableRows(data);
        }
      },
      { immediate: true, deep: true }
    );

    return {
      isUBOChangeLogDataLoading,
      hasTableRows,
      agGridOptions,
      gridAPI,
      onGridReady,
      infoIconOptions,
      themes,
      showChangesModal,
      handleModalClose,
      rowChangesData,
      typographySize
    };
  }
});
</script>

<style scoped lang="scss">
.ubo-change-log {
  margin-top: $spacing24;

  &__modal {
    &-content {
      display: flex;
      gap: $spacing20;

      &-container {
        flex: 1;
      }

      &-heading {
        margin-bottom: $spacing24;
      }
    }

    &:deep(.modal) {
      max-width: 969px;
    }
  }
}
</style>
