import { ref, computed, reactive } from "vue";
import {
  makeUUID,
  sizeColumnsToGridWidth,
  makeDateToMatchFormat
} from "@/utils";
import { UBOChangeLogTable, themes, shapes } from "@/constants";
import { navigateToUBOProfile } from "@/organisms/UltimateBeneficialOwnersList/UltimateBeneficialOwnersList.logic";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { Pill } from "@eb360-engineering/fe-component-library";

export function useUBOChangeLogTable({ table, router, thirdPartyId } = {}) {
  const gridAPI = ref(null);
  const rowChangesData = ref(null);
  const agGridOptions = reactive({
    gridOptions: {
      enableBrowserTooltips: true,
      suppressRowClickSelection: true,
      rowSelection: "multiple",
      suppressAutoSizing: true
    },
    rowData: [],
    columnDefs: [],
    getRowId: (params) => `${params.data.id}`
  });
  const columnInitialWidthMap = {
    [UBOChangeLogTable.CHANGE_TYPE.title]: 1,
    [UBOChangeLogTable.NAME.title]: 1,
    [UBOChangeLogTable.VIEW_PROFILE.title]: 1,
    [UBOChangeLogTable.DATE_OF_CHANGE.title]: 1,
    [UBOChangeLogTable.VIEW_CHANGES.title]: 1
  };
  const UBOLogChangeTypes = {
    insert: {
      text: "New owner added",
      theme: themes.SUCCESS_BLACK
    },
    update: {
      text: "Existing ownership updates",
      theme: themes.BLIZZARD_TRACE_BLUE
    },
    delete: {
      text: "Owner removed",
      theme: themes.NEW_YORK_PINK
    }
  };

  const hasTableRows = computed(() => !!agGridOptions.rowData.length);

  const onGridReady = ({ api }) => {
    gridAPI.value = api;
    sizeColumnsToGridWidth({
      gridAPI: api,
      sizingElement: table?.value?.$el
    });
  };

  const makeValueFormatterObject = ({
    field,
    _makeDateToMatchFormat = makeDateToMatchFormat
  } = {}) => {
    if (field === UBOChangeLogTable.DATE_OF_CHANGE.field) {
      return {
        valueFormatter: (params) =>
          `${_makeDateToMatchFormat({
            stringifiedDate: params?.value,
            dateFormat: "yyyy/MM/dd"
          })}`
      };
    }

    return {};
  };

  const makeUBOHeaderColumn = ({
    UBOKey,
    _makeValueFormatterObject = makeValueFormatterObject
  } = {}) => ({
    headerName: UBOChangeLogTable[UBOKey].title,
    field: UBOChangeLogTable[UBOKey].field,
    headerTooltip: UBOChangeLogTable[UBOKey].title,
    headerClass: "ag-cell-center",
    cellClass: "ag-cell-center",
    cellStyle: { textAlign: "center" },
    ..._makeValueFormatterObject({ field: UBOChangeLogTable[UBOKey].field }),
    ...(columnInitialWidthMap[UBOChangeLogTable[UBOKey].title] && {
      flex: columnInitialWidthMap[UBOChangeLogTable[UBOKey].title]
    }),
    unSortIcon: UBOKey === "DATE_OF_CHANGE",
    sortable: UBOKey === "DATE_OF_CHANGE",
    sort: UBOKey === "DATE_OF_CHANGE" ? "desc" : ""
  });

  const makeUBOViewProfileHeaderColumn = () => ({
    field: UBOChangeLogTable.VIEW_PROFILE.field,
    headerName: UBOChangeLogTable.VIEW_PROFILE.title,
    headerTooltip: UBOChangeLogTable.VIEW_PROFILE.title,
    cellRenderer: "BaseGridComponentWrapper",
    headerClass: "ag-cell-center",
    cellClass: "ag-cell-center",
    valueFormatter: () => null,
    sortable: false,
    flex: 1
  });

  const makeUBOViewChangesHeaderColumn = () => ({
    field: UBOChangeLogTable.VIEW_CHANGES.field,
    headerName: UBOChangeLogTable.VIEW_CHANGES.title,
    headerTooltip: UBOChangeLogTable.VIEW_CHANGES.title,
    cellRenderer: "BaseGridComponentWrapper",
    headerClass: "ag-cell-center",
    cellClass: "ag-cell-center",
    valueFormatter: () => null,
    sortable: false,
    flex: 1
  });

  const makeUBOViewProfileRow = (UBOData) => ({
    component: CallToAction,
    componentOptions: {
      icon: "file",
      size: 24,
      theme: themes.SECONDARY,
      shape: shapes.NONE,
      onClick: () =>
        navigateToUBOProfile({
          router,
          memberId: UBOData.memberId,
          thirdPartyId
        })
    }
  });

  const makeUBOViewChangesRow = (UBOData) => ({
    component: CallToAction,
    componentOptions: {
      icon: "eb-icon-eye",
      size: 24,
      theme: themes.SECONDARY,
      shape: shapes.NONE,
      onClick: () => {
        rowChangesData.value = UBOData.changes;
      }
    }
  });

  const makeUBOChangeTypeHeaderColumn = () => ({
    field: UBOChangeLogTable.CHANGE_TYPE.field,
    headerName: UBOChangeLogTable.CHANGE_TYPE.title,
    headerTooltip: UBOChangeLogTable.CHANGE_TYPE.title,
    cellRenderer: "BaseGridComponentWrapper",
    headerClass: "ag-cell-center",
    cellClass: "ag-cell-center",
    valueFormatter: () => null,
    sortable: false,
    flex: 1,
    minWidth: 250
  });

  const makeUBOChangeTypeRow = (UBOData) => ({
    component: Pill,
    componentOptions: {
      textOptions: {
        text: UBOLogChangeTypes[UBOData?.type]?.text
      },
      theme: UBOLogChangeTypes[UBOData?.type]?.theme
    }
  });

  const makeUBOTableHeaders = ({
    _makeUBOViewProfileHeaderColumn = makeUBOViewProfileHeaderColumn,
    _makeUBOChangeTypeHeaderColumn = makeUBOChangeTypeHeaderColumn,
    _makeUBOHeaderColumn = makeUBOHeaderColumn,
    _makeUBOViewChangesHeaderColumn = makeUBOViewChangesHeaderColumn
  } = {}) => {
    agGridOptions.columnDefs = Object.keys(UBOChangeLogTable).map((UBOKey) => {
      if (UBOKey === "VIEW_PROFILE") {
        return _makeUBOViewProfileHeaderColumn();
      }

      if (UBOKey === "CHANGE_TYPE") {
        return _makeUBOChangeTypeHeaderColumn();
      }

      if (UBOKey === "VIEW_CHANGES") {
        return _makeUBOViewChangesHeaderColumn();
      }

      return _makeUBOHeaderColumn({ UBOKey });
    });
  };

  const makeUBOTableRows = (data = []) => {
    agGridOptions.rowData = data?.map((UBOData) => {
      const rowId = makeUUID();
      const keys = Object.keys(UBOData);
      const rowData = keys.reduce((accumulation, key) => {
        accumulation[key] =
          UBOData[key] || UBOData[key] === false || UBOData[key] === 0
            ? UBOData[key]
            : "";

        return accumulation;
      }, {});

      return {
        ...rowData,
        memberId: UBOData?.isUBOActive ? makeUBOViewProfileRow(UBOData) : "",
        changes:
          UBOData?.type === "update" ? makeUBOViewChangesRow(UBOData) : "",
        type: makeUBOChangeTypeRow(UBOData),
        id: rowId
      };
    });
  };

  return {
    hasTableRows,
    agGridOptions,
    gridAPI,
    rowChangesData,
    makeUBOTableRows,
    onGridReady,
    makeUBOTableHeaders,
    makeValueFormatterObject,
    makeUBOHeaderColumn,
    makeUBOViewProfileHeaderColumn,
    makeUBOViewProfileRow,
    makeUBOChangeTypeHeaderColumn,
    makeUBOChangeTypeRow,
    makeUBOViewChangesHeaderColumn,
    makeUBOViewChangesRow
  };
}
