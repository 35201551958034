<template>
  <div>
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-row>
        <b-col cols="12">
          <b-tabs small>
            <!-- Contacts tab start -->
            <b-tab
              data-test-id="contacts-tab__table-title"
              :title="$t('eb360.contactsTabComponent.title')"
              class="my-4"
            >
              <b-card class="border-0">
                <b-card-body class="scrollFlow">
                  <b-navbar toggleable="lg" type="dark" variant="light">
                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                    <b-navbar-brand
                      href="#"
                      class="text-dark"
                      data-test-id="contacts-tab__table-title"
                    >
                      <strong>{{
                        $t("eb360.contactsTabComponent.contacts")
                      }}</strong>
                    </b-navbar-brand>

                    <b-collapse id="nav-collapse" is-nav>
                      <b-navbar-nav class="ml-auto">
                        <b-nav-form>
                          <!-- Add button start -->
                          <b-button
                            class="mr-2"
                            variant="outline-warning"
                            size="sm"
                            :disabled="isDisabled"
                            @click="showModal = !showModal"
                            data-test-id="contacts-tab__add-contact"
                          >
                            <font-awesome-icon icon="plus" />
                          </b-button>
                          <!-- Add button end -->

                          <!-- Reload button start -->
                          <b-button
                            class="mr-2"
                            variant="outline-info"
                            size="sm"
                            @click="getPrimaryContacts"
                            data-test-id="contacts-tab__refresh-table"
                          >
                            <font-awesome-icon icon="sync" />
                          </b-button>
                          <!-- Reload button end -->

                          <!-- Search bar start -->
                          <b-form-group>
                            <b-input-group
                              size="sm"
                              data-test-id="contacts-tab__table-search"
                            >
                              <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  :disabled="!filter"
                                  @click="filter = ''"
                                  >{{ $t("clearBtnText") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                          <!-- Search bar end -->
                        </b-nav-form>
                      </b-navbar-nav>
                    </b-collapse>
                  </b-navbar>

                  <!-- Contacts table data start -->
                  <b-table
                    responsive="sm"
                    :sort-by.sync="sortBy"
                    borderless
                    hover
                    bordered
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="primaryContactsItems"
                    :sort-desc="true"
                    :fields="fields"
                    data-test-id="contacts-tab__table"
                  >
                    <template v-slot:cell(contact_name)="data">{{
                      getContactName(data)
                    }}</template>
                    <template v-slot:cell(is_primary_contact)="data">
                      <b-badge
                        :variant="
                          data.item.is_primary_contact == 0
                            ? 'danger'
                            : 'success'
                        "
                        >{{
                          data.item.is_primary_contact == 0 ? "No" : "Yes"
                        }}</b-badge
                      >
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-dropdown
                        size="sm"
                        variant="outline"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template #button-content>
                          <font-awesome-icon icon="ellipsis-v" />
                        </template>
                        <b-dropdown-item
                          @click="onEditRow(data)"
                          :disabled="isDisabled"
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showResetPasswordModal(data.item)"
                          :disabled="isDisabled"
                        >
                          Reset Password
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="!isPrimaryContact(data)"
                          @click="onShowDeleteModal(data)"
                        >
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                  <!-- Contacts table data end -->

                  <b-row>
                    <b-col cols="2">
                      <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </b-col>
                    <b-col>
                      <b-pagination
                        class="float-right"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        aria-controls="my-table"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-tab>
            <!-- Contacts tab end -->

            <!-- Audit trail tab start -->
            <b-tab
              :title="$t('eb360.contactsTabComponent.auditTrial')"
              class="my-4"
              lazy
            >
              <AuditTrailTabComponent
                :isDisabled="false"
                :companyId="companyId"
                :thirdPartyId="thirdPartyId"
                :auditTrailType="2"
              />
            </b-tab>
            <!-- Audit trail tab end -->
          </b-tabs>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- Modal for add/edit contact start -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="
        isEditContact
          ? $t('eb360.contactsTabComponent.update')
          : $t('eb360.contactsTabComponent.create')
      "
      v-model="showModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="6">
              <b-form-group :label="$t('eb360.contactsTabComponent.fName')">
                <b-form-input
                  size="sm"
                  v-model="v$.form.firstName.$model"
                  :state="
                    v$.form.firstName.$dirty ? !v$.form.firstName.$error : null
                  "
                  :placeholder="$t('eb360.contactsTabComponent.enterFName')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group :label="$t('eb360.contactsTabComponent.lName')">
                <b-form-input
                  size="sm"
                  v-model="v$.form.lastName.$model"
                  :state="
                    v$.form.lastName.$dirty ? !v$.form.lastName.$error : null
                  "
                  :placeholder="$t('eb360.contactsTabComponent.enterLName')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label>{{ $t("eb360.contactsTabComponent.emailAdd") }}</label>
                <b-form-input
                  :disabled="isEditContact"
                  @change="contactExistEmail"
                  type="email"
                  size="sm"
                  v-model="v$.form.emailAddress.$model"
                  :state="
                    invalidEmailDomain
                      ? false
                      : v$.form.emailAddress.$dirty
                      ? !v$.form.emailAddress.$error
                      : null
                  "
                  :placeholder="$t('eb360.contactsTabComponent.enterEmail')"
                  id="contact-email-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="v$.form.emailAddress.$model && invalidEmailDomain"
                >
                  {{ invalidEmailDomainValue }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="v$.form.emailAddress.$error"
                  id="contact-email-feedback"
                  >Please enter a valid email</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group :label="$t('eb360.contactsTabComponent.country')">
                <v-select
                  :label="$t('eb360.contactsTabComponent.name')"
                  v-model="v$.form.country.$model"
                  :state="
                    v$.form && v$.form.country.$dirty
                      ? !v$.form.country.$error
                      : null
                  "
                  :options="countries"
                  :class="
                    v$.form.country.$error
                      ? 'border border-danger rounded form-control-small'
                      : 'form-control-small'
                  "
                  @update:modelValue="setCountryValue"
                >
                  <template slot="option" slot-scope="option">
                    <country-flag
                      v-if="option.icon"
                      :country="option.icon"
                      size="small"
                    />
                    <span>{{ "&nbsp;" + option.name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group :label="$t('eb360.contactsTabComponent.job')">
                <b-form-input
                  size="sm"
                  v-model="v$.form.jobTitle.$model"
                  :state="
                    v$.form.jobTitle.$dirty ? !v$.form.jobTitle.$error : null
                  "
                  :placeholder="$t('eb360.contactsTabComponent.enterJob')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group style="margin-top: 35px">
                <Checkbox
                  v-model="form.isPrimaryContact"
                  :label="$t('eb360.contactsTabComponent.isPrimary')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>
            {{
              isEditContact
                ? $t("eb360.contactsTabComponent.tpUpdating")
                : $t("eb360.contactsTabComponent.tpCreating")
            }}
          </p>
          <b-spinner type="grow" :label="$t('loadingSpinnerText')"></b-spinner>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isEditContact
                ? $t("eb360.contactsTabComponent.tpUpdated")
                : $t("eb360.contactsTabComponent.tpCreated")
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          v-if="!submiting"
          size="md"
          variant="outline-danger"
          @click="resetModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          size="md"
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation()"
          >{{ isEditContact ? $t("updateBtn") : $t("saveBtnText") }}</b-button
        >
      </template>
    </b-modal>
    <!-- Modal for add/edit contact end -->

    <!-- Modal for delete contact confirmation -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      title="Delete Third Party Contact"
      v-model="deleteModal"
    >
      Are you sure you want to delete ?
      <template v-slot:modal-footer>
        <b-button
          :disabled="submiting"
          size="md"
          variant="outline-danger"
          @click="onCloseDeleteModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          size="md"
          :disabled="submiting"
          variant="outline-success"
          @click="onDeleteRow"
          ><span v-if="submiting"
            ><b-spinner small variant="success"></b-spinner
          ></span>
          <span v-else>{{ $t("deleteBtnText") }}</span></b-button
        >
      </template>
    </b-modal>

    <!-- Modal for reset password confirmation -->
    <b-modal
      ref="modal-reset-password"
      title="Reset Password"
      @ok="resetPassword()"
    >
      <p class="my-4">
        Are you sure you want to reset password for
        <b>{{ contactDetails.created_by ? contactDetails.created_by : "" }}</b
        >?
      </p>
    </b-modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { thirdPartyContacts } from "@/constants";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import AuditTrailTabComponent from "../auditTrailTab/auditTrailTabComponent";
import { EventBus } from "@/event-bus";
import { mapState } from "vuex";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "ContactsTabComponent",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    AuditTrailTabComponent,
    Checkbox
  },
  data() {
    return {
      deleteModal: false,
      form: {
        firstName: null,
        lastName: null,
        jobTitle: null,
        country: null,
        emailAddress: null,
        isPrimaryContact: false
      },
      deleteRowData: {
        third_party_id: null,
        email_address: null,
        ibf_member_id: null,
        id: null
      },
      isEditContact: false,
      primaryContactsId: null,
      countryValue: null,
      submiting: false,
      responseSuccess: false,
      showModal: false,
      isLoadingData: false,
      filter: null,
      pageOptions: [5, 10, 25, 50, 100],
      sortBy: "id",
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      primaryContactsItems: [],
      fields: [
        {
          key: "id",
          label: this.$t("eb360.contactsTabComponent.id"),
          sortable: false
        },
        {
          key: "contact_name",
          label: this.$t("eb360.contactsTabComponent.name"),
          sortable: true
        },
        // { key: "lastname", label: "Last Name", sortable: true },
        {
          key: "job_title",
          label: this.$t("eb360.contactsTabComponent.job"),
          sortable: true
        },
        {
          key: "country_of_residence",
          label: this.$t("eb360.contactsTabComponent.rsidence"),
          sortable: true
        },
        {
          key: "email_address",
          label: this.$t("eb360.contactsTabComponent.emailAdd"),
          sortable: true
        },
        {
          key: "is_primary_contact",
          label: this.$t("eb360.contactsTabComponent.primary"),
          sortable: true
        },
        {
          key: "action",
          label: this.$t("eb360.contactsTabComponent.action"),
          sortable: false
        }
      ],
      contactDetails: {},
      invalidEmailDomain: false,
      invalidEmailDomainValue: null
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      emailAddress: { required, email },
      jobTitle: { required },
      country: { required }
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.country.countries,
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.primaryContactsItems.length;
    }
  },
  mounted() {
    this.getPrimaryContacts();
  },
  methods: {
    isPrimaryContact(data) {
      const value = data?.item?.is_primary_contact;

      return value === thirdPartyContacts.PRIMARY;
    },
    showResetPasswordModal(data) {
      this.contactDetails = data;
      this.$refs["modal-reset-password"].show();
    },

    // call reset password API
    resetPassword() {
      this.isLoadingData = true;

      let formData = {
        userId: this.userId,
        email: this.contactDetails.email_address
      };

      let result = HTTP(
        "post",
        "forgot/password",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          if (response.data.success == 1) {
            this.$bvToast.toast(response.data.message, {
              variant: "success",
              autoHideDelay: 2500,
              appendToast: false,
              noCloseButton: true
            });
            // this.reloadTable();
          } else {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: false
            });
          }
          this.isLoadingData = false;
        })
        .catch((error) => {
          this.isLoadingData = false;
          return [error];
        });
    },
    setCountryValue(value) {
      this.countryValue = value.name;
    },

    // check validation before creating/updating contact
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        if (this.invalidEmailDomain) {
          return false;
        } else if (this.isEditContact) {
          this.updateThirdPartyContact().then(this.getPrimaryContacts);
        } else {
          this.saveThirdPartyContact().then(this.getPrimaryContacts);
        }
      }
    },

    // get primary contact details
    async getPrimaryContacts() {
      this.primaryContactsItems = [];
      let formData = {
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId
      };
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/primarycontacts",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.status == 1 && result.status == 200) {
          this.primaryContactsItems = result.data.response;
          this.isLoadingData = false;
          this.$store.state.contactsTabLazyLoad = false;
        } else {
          this.primaryContactsItems = [];
          this.isLoadingData = false;
          this.$store.state.contactsTabLazyLoad = false;
        }
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    getContactName(data) {
      let firstName = data.item.firstname ? data.item.firstname : "";
      let lastname = data.item.lastname ? data.item.lastname : "";
      return `${firstName} ${lastname}`;
    },
    onShowDeleteModal(data) {
      this.deleteModal = true;
      this.deleteRowData = {
        third_party_id: data.item.third_party_id,
        email_address: data.item.email_address,
        ibf_member_id: data.item.ibf_member_id,
        id: data.item.id
      };
    },

    // call delete contact API
    async onDeleteRow() {
      let formData = {
        third_party_id: this.deleteRowData.third_party_id,
        email_address: this.deleteRowData.email_address,
        ibf_member_id: this.deleteRowData.ibf_member_id,
        id: this.deleteRowData.id
      };
      try {
        this.submiting = true;
        let result = await HTTP(
          "post",
          "thirdparty/deletecontact",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          this.onCloseDeleteModal();
          this.getPrimaryContacts();
          this.$bvToast.toast(result.data.message, {
            title: "",
            variant: "succes",
            autoHideDelay: 2000,
            appendToast: false
          });
          EventBus.emit("reloadThirdPartyDetailProfile", "");
        }
      } catch (error) {
        this.$bvToast.toast(error?.response?.data?.message, {
          title: "",
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: false
        });
      } finally {
        this.submiting = false;
      }
    },
    onCloseDeleteModal() {
      this.deleteModal = false;
      this.deleteRowData = {
        third_party_id: null,
        email_address: null,
        ibf_member_id: null,
        id: null
      };
    },

    // open edit contact modal
    onEditRow(data) {
      this.isEditContact = true;
      this.showModal = true;
      this.primaryContactsId = data.item.id;
      this.form = {
        firstName: data.item.firstname != null ? data.item.firstname : null,
        lastName: data.item.lastname != null ? data.item.lastname : null,
        jobTitle: data.item.job_title != null ? data.item.job_title : null,
        emailAddress: data.item.email_address,
        country:
          data.item.country_of_residence != null
            ? data.item.country_of_residence
            : null,
        isPrimaryContact: data.item.is_primary_contact == 1
      };
      this.countryValue =
        data.item.country_of_residence != null
          ? data.item.country_of_residence
          : null;
    },
    resetModal() {
      this.showModal = false;
      this.isEditContact = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.form = {
        firstName: null,
        lastName: null,
        jobTitle: null,
        emailAddress: null,
        country: null,
        isPrimaryContact: false
      };
      this.primaryContactsId = null;
      this.countryValue = null;
      this.invalidEmailDomain = false;
      this.invalidEmailDomainValue = null;
      this.v$.form.$reset();
    },

    // call create contact API
    async saveThirdPartyContact() {
      let formData = {
        firstname: this.form.firstName,
        lastname: this.form.lastName,
        country_of_residence: this.countryValue,
        job_title: this.form.jobTitle,
        third_party_id: this.thirdPartyId,
        company_id: this.companyId,
        email_address: this.form.emailAddress,
        is_primary_contact: this.form.isPrimaryContact ? 1 : 0,
        ibf_member_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/saveprimarycontacts",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          this.submiting = false;
          this.isLoadingData = false;
          this.$store.state.auditTrailTabLazyLoad = true;
          this.responseSuccess = false;
          EventBus.emit("reloadThirdPartyDetailProfile", "");
        } else if (result && result.data.message && result.status == 200) {
          this.submiting = false;
          this.isLoadingData = false;

          this.$bvToast.toast(result.data.message, {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
      } catch (err) {
        return [err];
      }
    },

    // call update contact API
    async updateThirdPartyContact() {
      let formData = {
        third_party_id: this.thirdPartyId,
        third_party_contact_id: this.primaryContactsId,
        firstname: this.form.firstName,
        lastname: this.form.lastName,
        company_id: this.companyId,
        country_of_residence: this.countryValue,
        job_title: this.form.jobTitle,
        email_address: this.form.emailAddress,
        is_primary_contact: this.form.isPrimaryContact ? 1 : 0,
        ibf_member_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/editprimarycontacts",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          this.submiting = false;
          this.isLoadingData = false;
          this.$store.state.auditTrailTabLazyLoad = true;
          this.responseSuccess = true;
          EventBus.emit("reloadThirdPartyDetailProfile", "");
        } else if (result && result.data.message && result.status == 200) {
          this.submiting = false;
          this.isLoadingData = false;
          this.$bvToast.toast(result.data.message, {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
      } catch (err) {
        return [err];
      }
    },
    async contactExistEmail() {
      let formData = {
        email: this.form.emailAddress
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/contact/exist-email",
          formData,
          makeAuthorizationHeader()
        );
        if (
          result.data.httpStatus == 200 &&
          result.data.success == 1 &&
          result.data.data > 0
        ) {
          this.invalidEmailDomain = true;
          this.invalidEmailDomainValue =
            "This Email already exist, Please try another Email.";
          return this.invalidEmailDomain;
        } else {
          this.invalidEmailDomain = false;
          this.invalidEmailDomainValue = null;
          return this.invalidEmailDomain;
        }
      } catch (err) {
        return [err];
      }
    }
  },
  props: {
    companyId: Number,
    thirdPartyId: Number,
    isDisabled: Boolean
  }
};
</script>
