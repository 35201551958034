import posthog from "posthog-js";
import { posthogEvents } from "@/constants";
import { createRouter, createWebHistory } from "vue-router";
import {
  checkAccessibilityRouteGard,
  thirdPartyRouterGard,
  eb360RouteGard
} from "@/utils";
import eb360Routes from "@/routes/eb360.routes";
import unauthenticatedRoutes from "@/routes/unauthenticated.routes";
import thirdPartyRoutes from "@/routes/thirdParty.routes";
import store from "@/store";

const allRoutes = [
  ...unauthenticatedRoutes,
  ...eb360Routes,
  ...thirdPartyRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes: allRoutes
});

router.beforeEach(async (to, __, next) => {
  checkAccessibilityRouteGard({ to, router });
  thirdPartyRouterGard({ to, router });
  eb360RouteGard({ to, router });
  next();
});

router.afterEach((to) => {
  if (store.getters["auth/isUserAuthenticated"]) {
    posthog.capture(posthogEvents.PAGEVIEW, {
      path: to.fullPath
    });
  }
});

export default router;
