import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeFetchUBODueDiligenceDataDTO,
  makeFetchUBOEntityDTO,
  makeFetchUBOThirdPartyDTO,
  thirdPartyFormsLanguagesDTO,
  thirdPartyHeaderConfigDataDTO,
  thirdPartyOCStatesListDTO,
  thirdPartyOrderTypeTComplianceDTO,
  makeTrainingsDTO,
  makeTrainingDTO,
  makeSendEmailForThirdPartyDTO,
  makeTrainingSummaryDTO,
  makeThirdPartyRiskAlertDTO,
  makeFetchUBOChangeLogDataDTO,
  makeThirdPartyRiskAlertsDetailsDTO
} from "./dto/thirdParty.dto";
import { makeCustomTypeDTO } from "@/services/automations/dto/automations.dto";

const fetchThirdPartyDetails = (formData) =>
  HTTP(
    "post",
    endpoints.THIRD_PARTY_DETAIL,
    formData,
    makeAuthorizationHeader()
  );

const fetchTComplianceOrderTypeProduct = async (productId) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_T_COMPLIANCE_ORDER_TYPE_PRODUCT(productId),
    null,
    makeAuthorizationHeader()
  );
  result.data = thirdPartyOrderTypeTComplianceDTO(result.data);
  return result;
};

const fetchThirdPartyProducts = async (thirdPartyId) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_PRODUCTS(thirdPartyId),
    null,
    makeAuthorizationHeader()
  );
  result.data = thirdPartyOrderTypeTComplianceDTO(result.data);
  return result;
};

const fetchFormsLanguagesList = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_FORMS_LANGUAGES_LIST(companyId),
    null,
    makeAuthorizationHeader()
  );
  return thirdPartyFormsLanguagesDTO(result.data);
};

const fetchOCStatesList = async () => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_OC_STATES_LIST,
    null,
    makeAuthorizationHeader()
  );
  return thirdPartyOCStatesListDTO(result.data);
};

const fetchHeaderConfigData = async (companyId, memberId) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_HEADER_CONFIG_DATA(companyId, memberId),
    null,
    makeAuthorizationHeader()
  );
  return thirdPartyHeaderConfigDataDTO(result.data.data);
};

const fetchEDDClientAllPackages = async (companyId) => {
  const result = await HTTP(
    "post",
    endpoints.THIRD_PARTY_EDD_CLIENT_ALL_PACKAGES(companyId),
    null,
    makeAuthorizationHeader()
  );
  return result.data;
};

const updateAutoFieldStatus = (formData) =>
  HTTP(
    "post",
    endpoints.UPDATE_AUTO_MSQ_STATUS,
    formData,
    makeAuthorizationHeader()
  );

const fetchCompanyOrganizations = async (companyId, formData) =>
  HTTP(
    "get",
    endpoints.COMPANY_ORGANIZATIONS_SEARCH(companyId),
    null,
    makeAuthorizationHeader(),
    formData
  );

const fetchCompanyCorporateData = async (companyId, organisationId) =>
  HTTP(
    "get",
    endpoints.COMPANY_ORGANIZATIONS_CORPORATE_DATA(companyId, organisationId),
    null,
    makeAuthorizationHeader()
  );

const fetchOrganisationsChangeLogData = async (organisationId) =>
  HTTP(
    "get",
    endpoints.ORGANIZATIONS_CHANGE_LOGS_DATA(organisationId),
    null,
    makeAuthorizationHeader()
  );

const fetchIdentifierTypes = async (companyId, type) => {
  const result = await HTTP(
    "get",
    endpoints.COMPANY_IDENTIFIER_TYPES(companyId, type),
    null,
    makeAuthorizationHeader()
  );

  return makeCustomTypeDTO(result);
};

const fetchUBOThirdPartyList = async (queryParameters) => {
  const result = await HTTP(
    "get",
    endpoints.UBO_THIRD_PARTY_LIST,
    null,
    makeAuthorizationHeader(),
    queryParameters
  );

  result.data = makeFetchUBOThirdPartyDTO(result?.data);

  return result;
};

const fetchUBOEntityData = async (queryParameters) => {
  const result = await HTTP(
    "get",
    endpoints.UBO_ENTITY_DATA,
    null,
    makeAuthorizationHeader(),
    queryParameters
  );

  return makeFetchUBOEntityDTO(result.data);
};

const fetchUBODueDiligenceReportData = async ({
  companyId,
  thirdPartyId,
  queryParameters
}) => {
  const result = await HTTP(
    "get",
    endpoints.UBO_DUE_DILIGENCE_REPORTS({ companyId, thirdPartyId }),
    null,
    makeAuthorizationHeader(),
    queryParameters
  );

  result.data = makeFetchUBODueDiligenceDataDTO(result);

  return result;
};

const createThirdPartyFromUBO = async ({
  postData = {},
  thirdPartyId = "",
  companyId = 0
}) =>
  HTTP(
    "post",
    endpoints.CREATE_THIRD_PARTY({ companyId, thirdPartyId }),
    postData,
    makeAuthorizationHeader()
  );

const fetchThirdPartyTrainingList = async ({ tenantId, queryParameters }) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_TRAINING_LIST,
    null,
    makeAuthorizationHeader({ tenantId }),
    queryParameters
  );
  const trainings = makeTrainingsDTO({
    trainings: result?.data?.data
  });
  const trainingSummaryList = makeTrainingSummaryDTO({
    trainings: result?.data?.summary
  });
  result.data = {
    trainings,
    trainingSummaryList
  };

  return result;
};

const fetchThirdPartyTrainingDetails = async ({
  tenantId,
  queryParameters
}) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_TRAINING,
    null,
    makeAuthorizationHeader({ tenantId }),
    queryParameters
  );
  result.data.data = makeTrainingDTO({
    training: result.data.data
  });
  return result;
};

const fetchThirdPartyBulkUploadSampleFile = (postData = {}) =>
  HTTP(
    "post",
    endpoints.THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE,
    postData,
    makeAuthorizationHeader(),
    null,
    false,
    "blob"
  );

const fetchThirdPartyRiskAlerts = async ({ thirdPartyId, tenantId }) => {
  const result = await HTTP(
    "get",
    endpoints.THIRD_PARTY_RISK_ALERTS(thirdPartyId),
    null,
    makeAuthorizationHeader({ tenantId })
  );

  result.data = makeThirdPartyRiskAlertDTO({
    riskAlerts: result?.data
  });

  return result;
};

const sendEmailForThirdPartyUpdateExcelSheet = async ({
  postData = {},
  _makeSendEmailForThirdPartyDTO = makeSendEmailForThirdPartyDTO
}) => {
  const response = await HTTP(
    "post",
    endpoints.SEND_EMAIL_FOR_THIRD_PARTY_BULK_UPDATE_EXCEL_SHEET,
    postData,
    makeAuthorizationHeader()
  );
  response.data = _makeSendEmailForThirdPartyDTO(response.data);
  return response;
};

const sendEmailForThirdPartyFilteredExport = async ({
  postData = {},
  _makeSendEmailForThirdPartyDTO = makeSendEmailForThirdPartyDTO
} = {}) => {
  const response = await HTTP(
    "post",
    endpoints.SEND_EMAIL_FOR_FILTERED_THIRD_PARTY_LIST,
    postData,
    makeAuthorizationHeader()
  );
  response.data = _makeSendEmailForThirdPartyDTO(response.data);
  return response;
};

const fetchUBOChangeLogData = async ({
  organisationId,
  _makeFetchUBOChangeLogDataDTO = makeFetchUBOChangeLogDataDTO
}) => {
  const response = await HTTP(
    "get",
    endpoints.UBO_CHANGE_LOG({ organisationId }),
    null,
    makeAuthorizationHeader()
  );

  response.data = _makeFetchUBOChangeLogDataDTO(response);

  return response;
};

const fetchThirdPartyRiskAlertsDetails = async ({ thirdPartyId, tenantId }) => {
  const result = await HTTP(
    "post",
    endpoints.THIRD_PARTY_RISK_ALERTS_DETAILS(thirdPartyId),
    {
      per_page: 100,
      page: 1
    },
    makeAuthorizationHeader({ tenantId })
  );

  result.data = makeThirdPartyRiskAlertsDetailsDTO({
    riskAlerts: result?.data
  });

  return result;
};

export const thirdPartyService = {
  fetchThirdPartyDetails,
  fetchTComplianceOrderTypeProduct,
  fetchThirdPartyProducts,
  updateAutoFieldStatus,
  fetchCompanyOrganizations,
  fetchCompanyCorporateData,
  fetchIdentifierTypes,
  fetchFormsLanguagesList,
  fetchUBOThirdPartyList,
  fetchOCStatesList,
  fetchHeaderConfigData,
  fetchEDDClientAllPackages,
  fetchOrganisationsChangeLogData,
  fetchUBOEntityData,
  createThirdPartyFromUBO,
  fetchUBODueDiligenceReportData,
  fetchThirdPartyTrainingList,
  fetchThirdPartyTrainingDetails,
  fetchThirdPartyBulkUploadSampleFile,
  fetchThirdPartyRiskAlerts,
  sendEmailForThirdPartyUpdateExcelSheet,
  sendEmailForThirdPartyFilteredExport,
  fetchUBOChangeLogData,
  fetchThirdPartyRiskAlertsDetails
};
