const thirdPartyKeysDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

const thirdPartyKeysAPIDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

const thirdPartyRiskAlertsDTO = {
  ALERT_CATEGORIES: "alertCategories",
  TOTAL_ALERTS: "totalAlerts",
  NAME: "name",
  CATEGORY_CODE: "categoryCode",
  COUNT: "count",
  ORGANISATION: "organisation",
  ASSOCIATE: "associate",
  UBO: "UBO",
  ORGANISATION_NAME: "name",
  ALERTS: "alerts",
  REMEDIATION_STATUS: "remediationStatus",
  CRITICAL: "critical",
  SERIOUS: "serious",
  MINOR: "minor",
  MODERATE: "moderate"
};

const thirdPartyRiskAlertsAPIDTO = {
  ALERT_CATEGORIES: "alert_categories",
  TOTAL_ALERTS: "total_alerts",
  NAME: "name",
  CATEGORY_CODE: "category_code",
  COUNT: "count",
  ORGANISATION: "organisation",
  ASSOCIATE: "associate",
  UBO: "ubo",
  ORGANISATION_NAME: "name",
  ALERTS: "alerts",
  REMEDIATION_STATUS: "remediation_status",
  CRITICAL: "critical",
  SERIOUS: "serious",
  MINOR: "minor",
  MODERATE: "moderate"
};

const riskAlertDetailsKeys = {
  ALERT_TYPE: "alertType",
  CATEGORY: "category",
  STAGE: "stage",
  LIABLE_PARTY: "liableParty",
  IS_UBO: "isUbo",
  ASSO_ID: "assoId",
  IS_ASSOCIATE: "isAssociate",
  INSERTED_DATE: "insertedDate",
  REMEDIATION_DATE: "remediationDate",
  EVENT_DATE: "eventDate",
  EVENT_DESCRIPTION: "eventDescription",
  SOURCE_NAME: "sourceName",
  SOURCE_URL: "sourceUrl",
  MORE_INFO: "moreInfo"
};

const riskAlertDetailsDTOKeys = {
  DATA: "data",
  CURRENT_PAGE: "currentPage",
  FIRST_PAGE_URL: "firstPageUrl",
  LAST_PAGE_URL: "lastPageUrl",
  NEXT_PAGE_URL: "nextPageUrl",
  PREV_PAGE_URL: "prevPageUrl",
  TOTAL: "total",
  PER_PAGE: "perPage",
  LAST_PAGE: "lastPage",
  HAS_ASSOCIATES: "hasAssociates",
  UBO_SCREENING_ORDERED: "uboScreeningOrdered",
  SCREENING_ORDERED: "screeningOrdered"
};

export {
  thirdPartyKeysDTO,
  thirdPartyKeysAPIDTO,
  thirdPartyRiskAlertsDTO,
  thirdPartyRiskAlertsAPIDTO,
  riskAlertDetailsKeys,
  riskAlertDetailsDTOKeys
};
