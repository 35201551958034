<template>
  <div
    class="third-party-risk-alerts__wrapper"
    data-test-id="third-party-risk-alerts__wrapper"
  >
    <BaseInfoBox
      v-if="feedbackMessage"
      :text="feedbackMessage"
      :theme="themes.INFO"
      :icon-options="defaultIcons.INFO"
    />
    <BaseInfoBox
      v-if="errorMessage"
      :text="errorMessage"
      :theme="themes.ERROR"
      :icon-options="defaultIcons.HIGH"
    />
    <div class="third-party-risk-alerts" data-test-id="third-party-risk-alerts">
      <BaseLoader v-if="isThirdPartyRiskAlertsLoading" />
      <div
        v-else
        class="third-party-risk-alerts__tab"
        data-test-id="third-party-risk-alerts__tab"
      >
        <ThirdPartyRiskAlertsHeader
          v-if="hasTableRows"
          :headerOptions="headerOptions"
        />
        <DataTable
          v-if="hasTableRows"
          :ag-grid-options="tableData"
          grid-height="auto"
          class="ag-theme-data-table"
          ref="data-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ShapeWithInfo from "@/molecules/ShapeWithInfo/ShapeWithInfo";
import BaseText from "@/atoms/BaseText/BaseText";
import DataTable from "@/organisms/DataTable/DataTable";
import ThirdPartyRiskAlertsHeader from "@/organisms/ThirdPartyRiskAlertsHeader/ThirdPartyRiskAlertsHeader";

import {
  border,
  defaultIcons,
  riskAlertCategoryText,
  riskAlertSummaryTableHeaders,
  shapes,
  themes,
  typographySize,
  riskAlertCategoryType
} from "@/constants";
import { mapActions, mapState } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";

export default {
  name: "ThirdPartyRiskAlerts",
  components: {
    BaseInfoBox,
    BaseLoader,
    DataTable,
    ThirdPartyRiskAlertsHeader
  },
  data() {
    return {
      themes,
      defaultIcons,
      typographySize,
      feedbackMessage: "",
      errorMessage: "",
      tableData: {
        gridOptions: {
          enableBrowserTooltips: true,
          suppressRowClickSelection: true,
          suppressRowHoverHighlight: true,
          suppressColumnHoverHighlight: true,
          suppressCellFocus: true,
          defaultColDef: {
            resizable: false,
            sortable: false
          }
        },
        domLayout: "autoHeight",
        rowData: [],
        columnDefs: []
      }
    };
  },
  computed: {
    ...mapState({
      isThirdPartyRiskAlertsLoading: (state) =>
        state.thirdParty.isThirdPartyRiskAlertsLoading,
      thirdPartyRiskAlerts: (state) => state.thirdParty.thirdPartyRiskAlerts,
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails
    }),
    hasTableRows() {
      return !!this.tableData.rowData?.length;
    },
    headerOptions() {
      return {
        heading: "Risk alerts by entity",
        description:
          "This table summarizes risk alerts by entity and category, showing the alert status.",
        alertsCount: this.thirdPartyRiskAlerts?.totalAlerts || 0,
        hasLegendVisible: true
      };
    }
  },
  async created() {
    await this.fetchThirdPartyRiskAlertsWrapper(this.$route.params.id);
    this.makeTableHeaders();
    this.makeTableRows(this.thirdPartyRiskAlerts?.alertCategories);
  },
  methods: {
    ...mapActions({
      fetchThirdPartyRiskAlerts: "thirdParty/fetchThirdPartyRiskAlerts"
    }),
    async fetchThirdPartyRiskAlertsWrapper(thirdPartyId) {
      try {
        this.feedbackMessage = "";
        this.errorMessage = "";
        this.feedbackMessage =
          (await this.fetchThirdPartyRiskAlerts(thirdPartyId)) || "";
      } catch ({ message = "" }) {
        this.errorMessage = message;
      }
    },
    makeHeaderColumn(key, organisationName) {
      return {
        headerName:
          riskAlertSummaryTableHeaders[key] ===
          riskAlertSummaryTableHeaders.ORGANISATION
            ? organisationName
            : riskAlertSummaryTableHeaders[key],
        field: key,
        cellRenderer: "BaseGridComponentWrapper",
        headerClass:
          riskAlertSummaryTableHeaders[key] !==
          riskAlertSummaryTableHeaders.ALERTS
            ? "ag-cell-center"
            : "",
        cellClass:
          riskAlertSummaryTableHeaders[key] !==
          riskAlertSummaryTableHeaders.ALERTS
            ? "ag-cell-center"
            : ""
      };
    },
    makeTableHeaders() {
      const organisationName =
        this.thirdPartyRiskAlerts?.alertCategories[0]?.organisation?.name ||
        this.thirdPartyDetails?.comp_name_eng;
      this.tableData.columnDefs = Object.keys(riskAlertSummaryTableHeaders).map(
        (key) => this.makeHeaderColumn(key, organisationName)
      );
    },
    makeTableRows(riskData) {
      this.tableData.rowData = riskData?.map((category) => ({
        ALERTS: this.makeAlertsRow(category),
        ORGANISATION: this.makeIconRow({
          type: riskAlertCategoryType.ORGANISATION,
          alerts: category.organisation.alerts,
          remediationStatus: category.organisation.remediationStatus
        }),
        ASSOCIATE: this.makeIconRow({
          type: riskAlertCategoryType.ASSOCIATE,
          alerts: category.associate.alerts,
          remediationStatus: category.associate.remediationStatus
        }),
        ULTIMATE_BENEFICIAL_OWNERS: this.makeIconRow({
          type: riskAlertCategoryType.UBO,
          alerts: category.ubo.alerts,
          remediationStatus: category.ubo.remediationStatus
        })
      }));
    },
    makeAlertsRow(category = {}) {
      return {
        component: BaseText,
        componentOptions: {
          text: `${category.name} (${category.count})`,
          size:
            category.count > 0
              ? typographySize.BODY_TEXT_BOLD
              : typographySize.BODY_TEXT,
          hasDefaultSpacingRemoved: true
        }
      };
    },
    makeIconWithOptions(iconOptions) {
      return {
        component: ShapeWithInfo,
        componentOptions: {
          iconOptions
        }
      };
    },
    makeIconRow({ alerts, remediationStatus, type }) {
      const alertOptions = this.getAlertOptions(alerts);
      const { srch_tpi_assos = [], ubo_status } = this.thirdPartyDetails || {};

      if (type === riskAlertCategoryType.ASSOCIATE && !srch_tpi_assos.length) {
        return this.makeIconWithOptions({
          icon: "eb-icon-minus",
          size: 16,
          theme: themes.LIGHT_GREY_INVERSE
        });
      }

      if (type === riskAlertCategoryType.UBO && parseInt(ubo_status) === 0) {
        return this.makeIconWithOptions({
          icon: "eb-icon-minus",
          size: 16,
          theme: themes.LIGHT_GREY_INVERSE
        });
      }

      if (!alertOptions?.length) {
        return this.makeIconWithOptions({ icon: "eb-icon-check", size: 16 });
      }

      const icons = alertOptions.map((alert = {}) => ({
        component: ShapeWithInfo,
        componentOptions: {
          shape: shapes.CIRCLE,
          borderStyle: alert.borderStyle,
          theme: alert.theme,
          textOptions: {
            text: alert.text,
            theme: alert.theme
          }
        }
      }));

      if (!remediationStatus) {
        icons.push(this.makeIconWithOptions({ icon: "eb-icon-spinner" }));
      }

      return { listOptions: icons };
    },

    getAlertOptions(alerts = {}) {
      const options = [];
      for (const [key, value] of Object.entries(alerts)) {
        if (value > 0) {
          switch (key) {
            case riskAlertCategoryText.CRITICAL:
              options.push({
                text: `${value}`,
                borderStyle: border.SOLID,
                theme: themes.ERROR
              });
              break;

            case riskAlertCategoryText.SERIOUS:
              options.push({
                text: `${value}`,
                borderStyle: border.SOLID_OUTLINE,
                theme: themes.ERROR_INVERSE
              });
              break;

            case riskAlertCategoryText.MODERATE:
              options.push({
                text: `${value}`,
                borderStyle: border.DOTTED
              });
              break;

            default:
              return null;
          }
        }
      }

      return options;
    }
  }
};
</script>
<style lang="scss" scoped>
.third-party-risk-alerts {
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid $grey;
  border-radius: $spacing16;
  box-shadow: $box-shadow-dropdown;

  &__tab {
    min-width: 1350px;
  }
}
</style>
